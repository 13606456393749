<template>
  <div></div>
</template>

<script>
import projectApiService from '@/network/project';

export default {
  name: 'simulationRedirect',
  async mounted() {
    const projectId = Number(this.$route.params.id);
    const studyId = Number(this.$route.params.study);
    const simulationId = Number(this.$route.params.simulation);

    let simulation = await projectApiService.getSimulation(projectId, studyId, simulationId);
    let configurationId = simulation.configuration.id;
    this.$router.push( { 
      name: 'ViewerContainer', 
      params: { 
        id: projectId,
        study: studyId,
        configuration: configurationId 
      } 
    });
  }
};
</script>
